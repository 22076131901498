/* Theming */

@import '~@angular/material/theming';
@include mat-core();
$epi-red: ( 50: #fae4e5, 100: #f2bcbf, 200: #e98f95, 300: #e0626a, 400: #d9404a, 500: #d21e2a, 600: #cd1a25, 700: #c7161f, 800: #c11219, 900: #b60a0f, A100: #ffe1e2, A200: #ffaeb0, A400: #ff7b7e, A700: #ff6265, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000, ));
$bo-epi-primary: mat-palette($mat-grey);
$bo-epi-accent: mat-palette($mat-yellow);
$bo-epi-warn: mat-palette($epi-red);
$bo-epi-theme: mat-light-theme($bo-epi-primary, $bo-epi-accent, $bo-epi-warn);
@include angular-material-theme($bo-epi-theme);

/* Fonts */

@font-face {
    font-family: Montserrat;
    src: url('/assets/fonts/Montserrat-Hairline.otf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: Montserrat;
    src: url('/assets/fonts/Montserrat-Light.otf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: Montserrat;
    src: url('/assets/fonts/Montserrat-Regular.otf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Montserrat;
    src: url('/assets/fonts/Montserrat-Bold.otf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: Montserrat;
    src: url('/assets/fonts/Montserrat-Black.otf') format('truetype');
    font-weight: bolder;
}


/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Montserrat !important;
    font-weight: 300;
}

.error-text {
    color: red;
}

.success-text {
    color: green;
}

.mat-display-1,
.mat-display-2,
.mat-form-field,
button {
    font-family: Montserrat !important;
}

.mat-form-field,
button {
    font-weight: 300;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #d21e2a!important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #d21e2a!important;
}